import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Helmet } from 'react-helmet'
import dayjs from 'dayjs'

import {
  DateSelectorButton,
  SingleDateScheduleGenerator,
  useSiteMetadata,
} from '../../components'
import { formatDateTime, groupEvents } from '../../utils'

/**
 * Layout for the `/schedule` page.
 * @category Pages
 * @function ScheduleIndexPage
 * @returns {jsx}
 */
function ScheduleIndexPage() {
  const { title, description, siteUrl, twitterUsername } = useSiteMetadata()

  const [todayDate, setTodayDate] = useState(null)
  const [isActive, setIsActive] = useState(null)
  const [dateBtnLabels, setDateBtnLabels] = useState(null)
  const [dateHeaders, setDateHeaders] = useState(null)
  const [userTimezone, setUserTimezone] = useState(null)

  const [sevenDayScheduleData, setSevenDayScheduleData] = useState(null)

  /**
   * Set initial values for `/schedule` state and fetches the current seven day schedule.
   *
   * 1. Set `todayDate` using {@link formatDateTime}; set `userTimezone` using `todayDate`
   * 2. Set `isActive` using formatted `todayDate`; "MM.DD"
   * 3. Set `dateBtnLabels` setDateBtnLabels `convertedDates.dateLabels`
   * 4. Fetch the schedule for the next seven days -- today and next 6 days
   * @category useEffect
   * @name loadSevenDaySchedule
   */
  useEffect(() => {
    // #1
    const currTime = formatDateTime(null, 'nyc-time')
    const timeZone = formatDateTime(null, 'get-timezone')
    setTodayDate(currTime)
    setUserTimezone(timeZone)

    // #2
    const todayInMMDD = formatDateTime(currTime, 'month-day')
    setIsActive(todayInMMDD)

    // #3
    const convertedDates = formatDateTime(currTime, 'get-this-weeks-dates')
    const { btnLabels, queryMatching, dateHeadings } = convertedDates
    setDateBtnLabels(btnLabels)
    setDateHeaders(dateHeadings)

    // #4
    const loadSevenDaySchedule = async () => {
      const startOfWeek = dayjs()
        .startOf('day')
        .toDate()
        .toISOString()
      const endOfWeek = dayjs()
        .add(7, 'day')
        .endOf('day')
        .toDate()
        .toISOString()

      const res = await fetch(
        `https://api.radiocult.fm/api/station/half-moon/schedule?startDate=${startOfWeek}&endDate=${endOfWeek}&timezone=UTC`,
        {
          headers: {
            'x-api-key': 'pk_6cd69e4aff67466ca32f2759d5ff3309',
          },
        }
      )

      const { schedules } = await res.json()

      const groupedEvents = groupEvents(schedules)
      setSevenDayScheduleData(groupedEvents)
    }
    return loadSevenDaySchedule()
  }, [])

  function toggleColumn(e) {
    if (isActive !== e.currentTarget.id) {
      setIsActive(e.currentTarget.id)
    }
  }

  return (
    <main className="container is-fluid black-bg-page">
      <Helmet defer={false}>
        <title>{`Schedule | ${title} | Ears to the concrete.`}</title>
        <meta
          property="og:title"
          content={`Schedule | ${title} | Ears to the concrete.`}
        />
        <meta property="og:url" content={`${siteUrl}/schedule/`} />
        <meta
          name="twitter:title"
          content={`Schedule | ${title} | Ears to the concrete.`}
        />
      </Helmet>

      <div className="columns is-mobile is-multiline">
        <div className="column">
          <h1 className="title is-size-3-desktop is-size-5-touch">
            Broadcast Schedule
          </h1>
          {userTimezone && (
            <h2 className="subtitle is-size-5-desktop is-size-6-touch">
              {`All times are ${userTimezone}.`}
            </h2>
          )}
        </div>
      </div>
      {dateBtnLabels && (
        <DateSelectorButton
          datesArr={dateBtnLabels}
          activeButton={isActive}
          toggleColumn={toggleColumn}
        />
      )}
      {sevenDayScheduleData && (
        <SingleDateScheduleGenerator
          currentTime={todayDate}
          datesArr={dateBtnLabels}
          scheduledShowsArr={sevenDayScheduleData}
          isActive={isActive}
        />
      )}
    </main>
  )
}

export default ScheduleIndexPage
